<template>
  <div>
    <div class="contentFrame">
      <div class="leftSide">
        <div class="subinfotext">特定商取引法に基づく表記</div>

        <div class="sentent_g1">
          <table
            width="100%"
            border="0"
            cellpadding="4"
            cellspacing="1"
            class="formBox"
          >
            <tbody>
              <tr>
                <td colspan="2" class="formListTh4">【販売者】</td>
              </tr>
              <tr>
                <td width="33%" class="formListTh3">■販売業者名</td>
                <td width="67%" class="formListTdw">
                  株式会社スカットコミュニケーションズ
                </td>
              </tr>
              <tr>
                <td class="formListTh3">■運営統括責任者</td>
                <td class="formListTdw">代表取締役　木村　安晴</td>
              </tr>
              <tr>
                <td class="formListTh3">■郵便番号</td>
                <td class="formListTdw">〒325-0303</td>
              </tr>
              <tr>
                <td class="formListTh3">■所在地</td>
                <td class="formListTdw">栃木県那須郡那須町高久乙3584-4</td>
              </tr>
              <tr>
                <td class="formListTh3">■電話番号</td>
                <td class="formListTdw">0287-74-6503</td>
              </tr>
              <!--
          <tr>
            <td class="formListTh3">■FAX番号 </td>
           <td class="formListTdw">0287-74-6506</td>
            <td class="formListTdw">0287-69-6070</td>
          </tr>
          -->
              <tr>
                <td class="formListTh3">■お問い合わせメールアドレス</td>
                <td class="formListTdw">
                  <a href="mailto:info@santaclaus-embassy.com"
                    >info@santaclaus-embassy.com</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <table
            width="100%"
            border="0"
            cellpadding="4"
            cellspacing="1"
            class="formBox"
          >
            <tbody>
              <tr>
                <td colspan="2" class="formListTh4">【販売に関する規約】</td>
              </tr>

              <tr>
                <td width="33%" class="formListTh3">■表示価格に含まれるもの</td>
                <td width="67%" class="formListTdw">
                  商品価格・消費税(商品は税込価格で表示しています)
                </td>
              </tr>

              <tr>
                <td class="formListTh3">
                  <div style="vertical-align: text-top; height: 90px">
                    ■商品以外の必要料金
                  </div>
                </td>
                <td class="formListTdw">
                  ○送料<br />

                  クロネコゆうパケットの場合は、個別送料:全国一律320円(税込み)<br />

                  宅急便の場合は、個別送料は以下の表に基づく<br /><a
                    target="_blank"
                    href="https://santaclaus-embassy.com/preview/images/deliverprice.png"
                  >
                    <img
                      src="https://santaclaus-embassy.com/preview/images/deliverprice.png"
                      alt=""
                  /></a>
                  <br />

                  ○代金支払の振込手数料<br />
                  銀行、郵便局等の金融機関からお支払いの場合、振込手数料はお客様のご負担となります。
                </td>
              </tr>
              <tr>
                <td class="formListTh3">■商品代金支払方法</td>
                <td class="formListTdw">
                  コンビニ支払・クレジットカード・銀行振り込み・郵便局払込取扱票より選択。
                </td>
              </tr>
              <tr>
                <td class="formListTh3">■商品代金支払期限</td>
                <td class="formListTdw">ご注文データ送信完了後10日以内</td>
              </tr>
              <tr>
                <td class="formListTh3">■商品引渡し期間</td>
                <td class="formListTdw">
                 クロネコゆうパケットは12月10日頃より順次発送を開始いたします。<br />
宅急便オプションをご利用の方は12月17日から25日の間にお届けいたします。<br />
                  日時指定が可能な商品については、ご注文時に日時指定が可能です。<br />
                  （日時指定の有無、またはオプション等の選択により一部お届け時期が異なるものについては商品ページで説明しています）
                </td>
              </tr>
              <tr>
                <td class="formListTh3">■発送方法</td>
                <td class="formListTdw">
                  各商品のサイズ等に条件に基づき、ヤマト運輸
                  クロネコゆうパケット・宅急便より選択
                </td>
              </tr>
              <tr>
                <td class="formListTh3">■申込みの有効期限</td>
                <td class="formListTdw">商品発送日まで有効</td>
              </tr>
            </tbody>
          </table>
          <table class="formBox" border="0" cellpadding="4" cellspacing="1">
            <tbody>
              <tr>
                <td colspan="2" class="formListTh4">【返品交換特約】</td>
              </tr>
              <tr>
                <td width="212" class="formListTh3">
                  <div style="vertical-align: text-top; height: 65px">
                    ■返品受け入れ理由
                  </div>
                </td>
                <td width="434" class="formListTdw">
                  商品の返品・交換には初期不良の場合以外では応じられません。
                </td>
              </tr>
              <tr>
                <td class="formListTh3">■不良品</td>
                <td class="formListTdw">
                  商品をお届けする際には万全を期しておりますが、商品に汚損、乱丁、落丁など瑕疵ある場合、商品到着後7日間以内にご連絡いただいた場合には交換いたします。
                </td>
              </tr>
              <tr>
                <td class="formListTh3">■返品送料</td>
                <td class="formListTdw">
                  商品に瑕疵ある場合のご返送料金は、弊社が負担させていただきます。
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="rightSide">
        <sideContents />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created: function () {
    this.$parent.middle = 2;
  },
};
</script>

<style scoped lang="scss">
.formBox {
  td {
    border-bottom: solid 1px #ccc;
    img {
      max-width: 100%;
    }
  }
}
$pc: 1024px; // PC
$tab: 680px; // タブレット
$sp: 480px; // スマホ
@mixin pc {
  @media (max-width: ($pc)) {
    @content;
  }
}

@mixin tab {
  @media (max-width: ($tab)) {
    @content;
  }
}

@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

.subinfotext {
  color: #454552;
  font-size: 1.5em;
  font-weight: bold;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../assets/images/ilst1.png") no-repeat left center /
    contain;
  @include sp {
    padding-left: 10vw;
    font-size: 1em;
  }
}
.sentent_r1 {
  color: #d72e23;
  line-height: 1.7;
  margin: 2em 0;
}
.sentent_g1 {
  text-align: left;
  color: #454552;
  line-height: 1.7;
  margin: 2em 0;
}
.subsent2 {
  margin-bottom: 2em;
  text-align: left;
  border: solid 3px #d72e23;
  padding: 1em;
  h3 {
    color: #d72e23;
    padding: 2em 0;
  }
  h4 {
    color: #d72e23;
    margin: 1em 0;
    padding-left: 1em;
    border-left: double 10px #d72e23;
  }
}
.centeimg1 {
  padding: 1em 0;
  text-align: center;
  img {
    width: 33%;
    max-width: 100%;
  }
}
.centeimg2 {
  padding: 1em 0;
  text-align: center;
  img {
    width: 90%;
    max-width: 100%;
  }
}
</style>